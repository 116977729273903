/* styles.css */
.icon {
  display: none;
}

.text {
  display: block;
}

.bold-name {
  font-weight: bold;
}
.PrivateSwitchBase-input {
  opacity: 1 !important;
}

  /* Add a specific class for the button */
.system-button {
  font-size: 0.9rem; /* Adjust font size for mobile */
  padding: 8px 16px; /* Add padding for better touch targets */
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  max-width: 150px;
  margin: 0 auto; /* Center the button */
}

  /* Apply only to CompanyUsersList component */
  .company-users-table .icon {
    display: none;
  }

  .company-users-table .text {
    display: block;
  }

  .company-users-table .bold-name {
    font-weight: bold;
  }

  .spaced-paragraph {
    margin-bottom: 36px;
  }

@media (max-width: 600px) {
  /* Add system button styles for mobile */
  .system-button {
    font-size: 0.8rem;
    padding: 6px 12px;
    width: 100%;
    max-width: none;
  }

  /* Ensure these only affect the CompanyUsersList component */
  .company-users-table thead {
    display: none;
  }

  .company-users-table .table-row-mobile {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }

  .company-users-table .table-cell-mobile {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }

  .company-users-table .table-cell-mobile:last-child {
    margin-bottom: 0;
  }

  /* New: Align name, email, and phone on the same line */
  .company-users-table .name-contact-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
  }

  .company-users-table .name-contact-row .bold-name {
    font-weight: bold;
  }

  .company-users-table .name-contact-row .contact-icons {
    display: flex;
    gap: 10px;
  }

  /* Style for icons to ensure alignment */
  .company-users-table .contact-icons a {
    color: rgb(22, 119, 255);
    text-decoration: none;
  }

  /* Optional: Adjust icon size for better appearance on mobile */
  .company-users-table .contact-icons .MuiIconButton-root {
    padding: 0;
    font-size: 1rem;
  }
  /* CLOSE the CompanyUsersList component */

  .icon {
    display: block;
  }

  td.MuiTableCell-sizeMedium {
    padding: 6px;
  }

  .default-class,
  .hide-on-mobile {
    line-height: 1.2rem;
  }

  .hide-on-mobile,
  .text {
    display: none!important;
  }

  .table-row-mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }

  .table-cell-mobile {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }

  .table-cell-mobile:last-child {
    margin-bottom: 0;
  }
}
.hide-navigation header,
.hide-navigation .drawer,
.hide-navigation .horizontal-bar,
.hide-navigation .toolbar,
.hide-navigation .add-customer {
  display: none;
}
.remove-padding-top {
padding-top: 0 !important;
}
ul.daily-report {
padding-left: 0; /* To remove default padding from the ul */
}

ul.daily-report li {
list-style-type: none; /* Remove the default bullet points */
margin: 20px 0px; /* Add some spacing between list items */
padding-bottom: 14px; /* Add some spacing between list items */
border-bottom: 1px solid #ccc; /* Add a border between list items */
}

ul.daily-report li div {
display: flex; /* Flexbox for aligning the labels and values */
margin-bottom: 5px; /* Add some spacing between rows */
}

ul.daily-report li div strong {
width: 150px; /* Fixed width for labels */
display: inline-block; /* Ensures the width is applied */
}
.react-datepicker-popper {
transform: translate(.5vw, 283px) !important;
z-index: 20 !important;
}
.react-datepicker__triangle {
display: none !important;
}
.customSearchFormlabel,
.css-h6vcjs-MuiFormControlLabel-root .MuiFormControlLabel-label {
font-size: 14px!important;
line-height: 14px!important;
color: #666!important;
}
.customSearchFormcheckbox,
.css-1rekf1g-MuiButtonBase-root-MuiCheckbox-root.size-small {
margin: 0px 12px;
}
.css-pnqz1-MuiChip-root.MuiChip-colorSuccess {
background-color: #ffffff;
color: #4d9427;
border:1px solid #4d94277b;
}
.css-1c73rpd-MuiChip-root.MuiChip-colorSuccess {
color: #ffffff;
background-color: #4d9427;
font-weight: 700;
}